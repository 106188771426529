<template>
  <div>
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600"
      :persistent="isMakingRequest"
      scrollable
    >
      <v-card :loading="isMakingRequest">
        <v-card-title class="d-flex justify-space-between align-center primary white--text pb-4">
          <div>
            Social Sentiments
          </div>

          <div class="d-flex justify-center align-center">
            <!-- If there are more than one influencers -->
            <div
              v-if="influencers.length > 1"
              class="text-subtitle-2"
            >
              {{ influencers.length }} Influencers
            </div>

            <template
              v-if="influencers.length === 0"
            >
              <span class="mr-4 text-subtitle-2">
                Multiple Query?
              </span>

              <v-switch
                v-model="isMultiple"
                @change="inputValue = ''"
                :disabled="isMakingRequest"
                :readonly="influencers.length > 0"
                class="mt-0 mr-0"
                color="accent"
                hide-details
                inset
              ></v-switch>
            </template>
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- If there are error messages -->
          <p
            v-for="(message, index) in errorMessages"
            :key="index"
            class="red--text"
          >
            {{ message }}
          </p>

          <!-- Show the error messages here -->
          <p v-if="items.length > maxItems" class="red--text">Please enter a maximum of {{ maxItems }} links at once</p>

          <!-- Show error messages for all invalid profile/links -->
          <p v-for="(item, index) in invalidItems" :key="index" class="red--text">
            <span v-if="isMultiple">
              Link #{{ items.indexOf(item) + 1 }} ({{ item }})
            </span>
            <span v-else>
              Link
            </span>

            <span>
              is not valid
            </span>
          </p>

          <!-- Show the select inputs -->
          <div
            class="d-flex mt-3 mt-sm-0 mb-4"
            v-if="influencers.length === 0"
          >
            <!-- show the input for searching terms -->
            <v-select
              outlined
              hide-details
              v-model="selectedType"
              :items="typeOptions"
              placeholder="Link Type"
              class="rounded-r-0"
              :readonly="influencers.length > 0"
            ></v-select>

            <!-- the select options for different platforms -->
            <platform-selector
              :show-youtube="selectedType !== 'profile'"
              :value="selectedPlatform"
              class="platform-selector rounded-l-0"
              @input="handlePlatformChange"
              :readonly="influencers.length > 0"
              outlined
            />
          </div>

          <template v-if="influencers.length > 0">
            <!-- If there's only one influencer -->
            <template v-if="influencers.length === 1">
              <div class="d-flex align-center mb-6">
                <v-img
                  height="30"
                  width="30"
                  max-width="30"
                  class="mr-3"
                  :src="platformIconMap[influencers[0].platform]"
                />

                <profile-chip
                  :data="influencers[0].preview || influencers[0]"
                  :platform="influencers[0].platform"
                />
              </div>
            </template>
            <!-- Otherwise -->
            <template v-else>
              <v-slide-group class="mb-6">
                <v-slide-item
                  v-for="influencer in influencers"
                  :key="influencer.id"
                >
                  <profile-chip
                    :data="influencer.preview || influencer"
                    :platform="influencer.platform"
                    class="mr-3"
                  />
                </v-slide-item>
              </v-slide-group>
            </template>
          </template>

          <template v-else>
            <!-- Show the textarea input for bulk -->
            <v-textarea
              v-if="isMultiple"
              outlined
              hide-details
              v-model="inputValue"
              :placeholder="`${selectedType === 'profile' ? 'Profile' : 'Posts'} URLs\nSeparate each link with a new line\nMaximum of ${maxItems} links allowed`"
            />

            <!-- Otherwise -->
            <template v-else>
              <!-- If profile is selected -->
              <div v-if="selectedType === 'profile'">
                <profile-selector
                  @change="handleProfileSearch"
                  :platform="selectedPlatform"
                  :use-combobox="true"
                  :hide-no-data="true"
                  type="search"
                  label="Search Profile"
                  outlined
                />
              </div>

              <!-- If post is selected -->
              <v-text-field
                v-else
                outlined
                hide-details
                v-model="inputValue"
                :placeholder="`Enter ${selectedPlatform === 'youtube' ? 'Video' : 'Post'} URL`"
              ></v-text-field>

              <!-- When post is selected, also show additional inputs -->
              <v-expansion-panels
                v-if="selectedType === 'post'"
                class="mt-4"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="font-weight-bold">
                    <div class="d-flex align-center">
                      <v-icon left color="text">
                        upcoming
                      </v-icon>

                      Run deep brand related analysis on this post
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="pt-3">
                      Get better audience engagement insights on your paid post for your brand!
                    </div>
                    <div class="mx-auto" style="max-width: 300px;">
                      <lottie-animation
                        loop
                        file="data-analysis.json"
                      />
                    </div>

                    <!-- Show a divider -->
                    <v-divider />

                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="postAdditionalInputs.brandName"
                          :hide-details="!$v.postAdditionalInputs.brandName.$anyError"
                          :error-messages="$v.postAdditionalInputs.brandName.$anyError ? ['Please enter a valid brand name'] : null"
                          @blur="$v.postAdditionalInputs.brandName.$touch"
                          label="Brand Name"
                          counter="30"
                          outlined
                        />
                      </v-col>

                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="postAdditionalInputs.brandHandle"
                          :hide-details="!$v.postAdditionalInputs.brandHandle.$anyError"
                          :error-messages="$v.postAdditionalInputs.brandHandle.$anyError ? ['Please enter a valid brand username'] : null"
                          @blur="$v.postAdditionalInputs.brandHandle.$touch"
                          label="Brand Username"
                          counter="40"
                          outlined
                        />
                      </v-col>

                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="postAdditionalInputs.productName"
                          :hide-details="!$v.postAdditionalInputs.productName.$anyError"
                          :error-messages="$v.postAdditionalInputs.productName.$anyError ? ['Please enter a product name'] : null"
                          @blur="$v.postAdditionalInputs.productName.$touch"
                          label="Product Name"
                          counter="60"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip
                label
                color="primary"
                v-on="on"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>

                {{ nFormatter(availableModuleUsage) }} Reports
              </v-chip>
            </template>

            <span>
              You have {{ availableModuleUsage }} reports available to generate
            </span>
          </v-tooltip>

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="shouldCTABeDisabled"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, maxLength } from "vuelidate/lib/validators"
import platformRegex from "@/helpers/platformRegex"
import isURL from "@/helpers/isURL"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Additional post inputs generator
const postAdditionalInputs = () => ({
  brandName: "",
  brandHandle: "",
  productName: "",
})

// Export the SFC
export default {
  // Name of the component
  name: "SocialSentimentsForm",

  // Register the components
  components: {
    ProfileChip,
    LottieAnimation,
    ProfileSelector,
    PlatformSelector,
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    },

    influencers: {
      type: Array,
      default: () => []
    }
  },

  // Define local data variables
  data: () => ({
    isMakingRequest: false,
    selectedType: "post",
    selectedPlatform: "instagram",

    isMultiple: false,
    inputValue: "",
    postAdditionalInputs: postAdditionalInputs(),

    minItems: 1,

    // The error messages for invalid inputs
    errorMessages: []
  }),

  // Define local computable properties
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("social-sentiment")
    },

    /**
     * Compute the maximum items allowed
     *
     * @returns {Number}
     */
    maxItems() {
      return Math.min(this.availableModuleUsage, 10)
    },

    /**
     * Compute the input string and try to generate an array
     *
     * @returns {Array}
     */
    items() {
      const breakCharacter = "*STRING&ENDS&HERE*"

      return this.inputValue
        .replaceAll(" ", breakCharacter)
        .replaceAll("\n", breakCharacter)
        .split(breakCharacter)
        .map((item) => item.trim())
        .filter((item) => Boolean(item))
    },

    /**
     * Make sure that all the items are valid string values, otherwise return an array of line numbers which are invalid
     *
     * @returns {Array}
     */
    invalidItems() {
      return this.items.filter((item) => {
        // Check if the input is kind of an URL
        if (isURL(item)) {
          // If the type selected is "post", it only needs to be a valid URL
          if (this.selectedType === "post") {
            return false
          }

          // Otherwise, try getting the regex match for it
          const match = item.match(platformRegex[this.selectedPlatform])

          // If the match is found, hence found the userID for this link
          if (match !== null && match[1]) {
            return false
          } else {
            return true
          }
        }
        // Fallback to true
        else {
          // If the selected type is "post"
          if (this.selectedType === "post") {
            return true
          }

          // Fallback
          return false
        }
      })
    },

    /**
     * Whether or not the submit button be disabled
     *
     * @returns {Boolean}
     */
    shouldCTABeDisabled() {
      // If there isn't enough reports available
      if (this.availableModuleUsage < this.minItems) { return true }

      // If there are less than minimum items
      if (this.items.length < this.minItems) { return true }

      // If there are more items than the maximum
      if (this.items.length > this.maxItems) { return true }

      // If it's making a request
      if (this.isMakingRequest) { return true }

      // If there are invalid items
      if (this.invalidItems.length > 0) { return true }

      // If there's some invalid input
      if (this.errorMessages.length > 0) { return true }

      // Otherwise
      return false
    },

    /**
     * Select input possible option values for type
     *
     * @returns {Array}
     */
    typeOptions() {
      const items = [
        {
          text: "Profile",
          value: "profile"
        },

        {
          text: "Post",
          value: "post"
        }
      ]

      return items.filter((item) => {
        if (this.selectedPlatform === "youtube" && item.value === "profile") {
          return false
        } else {
          return true
        }
      })
    },

    /**
     * Get the platforms from the influencers
     *
     * @returns {Array}
     */
    platforms() {
      return Array.from(new Set(this.influencers.map(influencer => influencer.platform)))
    }
  },

  // Define vuelidate validations
  validations: {
    postAdditionalInputs: {
      brandName: {
        required,
        maxLength: maxLength(30),
      },

      brandHandle: {
        required,
        maxLength: maxLength(40),
      },

      productName: {
        required,
        maxLength: maxLength(60),
      },
    }
  },

  // Options API watcher functions
  watch: {
    typeOptions(items) {
      // If we only have one type option, select it as default
      if (items.length === 1) {
        this.selectedType = items[0].value
      }
    },

    influencers: {
      deep: true,
      handler(items) {
        // Reset the error messages
        this.errorMessages = []

        // Set the query for influencers
        this.selectedType = "profile"
        this.inputValue = ""

        // If there are no items selected
        if (items.length === 0) {
          // Stop further execution
          return
        }

        // If there are multiple platforms
        if (this.platforms.length > 1) {
          // Add an error message
          this.errorMessages.push("You can only run social sentiment analysis on a single platform at a time")
        }

        // If the platforms has anything but instagram or tiktok
        if (this.platforms.some((platform) => !["instagram", "tiktok"].includes(platform))) {
          // Add an error message
          this.errorMessages.push("You can only run social sentiment analysis on Instagram or TikTok")
        }

        // If there are no error messages
        if (this.errorMessages.length > 0) {
          // Stop further execution
          return
        }

        // If there are more than 1 influencers
        if (items.length > 1) {
          // Mark it as multiple
          this.isMultiple = true
        }

        // Loop through each influencer
        for (const item of items) {
          // Append the value
          this.inputValue += item.username + "\n"
        }
      },
    }
  },

  // Define local method functions
  methods: {
    /**
     * When the platform selector's value has been changed
     *
     * @param {String} value
     * @returns {void}
     */
    handlePlatformChange(value) {
      this.selectedPlatform = value

      // If the selected platform is youtube
      if (value === "youtube") {
        this.selectedType = "post"
      }

      // Also reset the input value
      this.inputValue = ""
    },

    /**
     * Handle ProfileSelector callback value
     *
     * @param {Object} selectedUser
     * @return {void}
     */
    handleProfileSearch(selectedUser) {
      // If there is no value, return
      if (!selectedUser) {
        this.inputValue = ""

        return false
      }

      // Mark it as the selected influencer
      this.selectedInfluencer = selectedUser

      // Check if the value is a URL
      if (isURL(selectedUser)) {
        // Try to extract the value from it
        const match = selectedUser.match(platformRegex[this.selectedPlatform])

        // If found a match
        if (match !== null && match[1]) {
          // Also update the value of the form input
          this.inputValue = match[1]

          // return to make sure the function ends here
          return true
        }
      }
      // Otherwise
      else {
        // If the value is a valid string
        if (typeof selectedUser === "string") {
          // Also update the value of the form input
          this.inputValue = selectedUser
        }
        // Otherwise
        else {
          this.inputValue = selectedUser.value
        }
      }
    },

    /**
     * When the submit button is clicked
     *
     * @returns {void}
     */
    async handleSubmit() {
      // Whether or not to send post additional inputs
      let needToSendPostAdditionalInputs = false

      // If individual post mode is selected
      if (!this.isMultiple && this.selectedType === "post") {
        // Run the vuelidate validations
        await this.$v.postAdditionalInputs.$touch()

        // If even a single of postAdditionalInputs has some value
        if (Object.values(this.postAdditionalInputs).some((value) => value)) {
          // Set the flag to true
          needToSendPostAdditionalInputs = true

          // If the vuelidate validations are not passed
          if (this.$v.postAdditionalInputs.$invalid) {
            this.$store.dispatch("toasts/add", {
              text: "Please fill all the required fields"
            })

            return false
          }
        }
      }

      // If some request is in queue
      if (this.isMakingRequest) {
        this.$store.dispatch("toasts/add", {
          text: "Please wait ..."
        })

        return false
      }

      // Check if the there are at least 2 items
      else if (this.items.length < this.minItems) {
        this.$store.dispatch("toasts/add", {
          text: `Please add at least ${this.minItems} links`
        })

        return false
      } else if (this.selectedType === null) {
        this.$store.dispatch("toasts/add", {
          text: "Please select an export file type"
        })

        return false
      }

      // Otherwise, make the request
      else {
        // Put a loader
        this.isMakingRequest = true

        // Keep an error counter
        let successCounter = 0

        // For each link
        for (const query of this.items) {
          try {
            // Initiate the request data
            const requestData = {
              query,
              for: this.selectedType,
              platform: this.selectedPlatform,
            }

            // If the post additional inputs are needed
            if (needToSendPostAdditionalInputs) {
              requestData.brand_query = {
                brand_name: this.postAdditionalInputs.brandName,
                brand_handle: this.postAdditionalInputs.brandHandle,
                product_name: this.postAdditionalInputs.productName,
              }
            }

            // Make the request
            await axios({
              url: "/api/social-sentiments",
              method: "POST",
              data: requestData
            })

            // Increment the success counter
            successCounter++
          }
          catch (error) {
            this.$store.dispatch("toasts/add", {
              text: error?.response?.data?.error?.message || "An error occurred!"
            })

            logger({ type: "SocialSentiments/Form/handleSubmit Error", error })
          }
        }

        // Hide the loader
        this.isMakingRequest = false

        // Hide the dialog
        this.$emit('input', false)
        this.$emit('created')

        // Refresh the user's balance
        fetchProfile()

        // If the success counter is there
        if (successCounter > 0) {
          // Show the success message
          this.$store.dispatch("toasts/add", {
            text: "Your report is being processed, we'll notify you once it's ready"
          })
        }
      }
    },
  },
}
</script>
